export const featureImageAndTextWithLink = {
    imgUrl: 'https://source.unsplash.com/featured/584x584?people',
    imgAlt: 'Image alt text',
    richTextContent: `
        <p>You don’t need to prove anyone’s fault to be eligible.</p>
        <p>Typically, employees are covered by the employer's insurer.  In Victoria this is the WorkCover compensation scheme.</p>
        <p>If you are an employee of a Commonwealth or prescribed large business, compensation is available to you under the national Comcare scheme.</p>
        <p><a href="/another-page">Visit our Comcare page for information specific to this scheme.</a></p>
        `
};

export const featureImageAndTextWithHeading = {
    imgUrl: 'https://source.unsplash.com/featured/584x584?people',
    imgAlt: 'Image alt text',
    richTextContent: `
        <h4>If you suffer from a workplace injury or have been diagnosed with a disease from workplace exposure, you can access lost wages and payment of medical expenses through the Workers Compensation scheme.</h4>
        <p>You don’t need to prove anyone’s fault to be eligible.</p>
        <p>Typically, employees are covered by the employer's insurer.  In Victoria this is the WorkCover compensation scheme.</p>
        <p>If you are an employee of a Commonwealth or prescribed large business, compensation is available to you under the national Comcare scheme.</p>
        <p><a href="/another-page">Visit our Comcare page for information specific to this scheme.</a></p>
        `,
};

export const featureImageAndTextRight = {
    imgUrl: 'https://source.unsplash.com/featured/584x584?people',
    imgAlt: 'Image alt text',
    richTextContent: `
        <h4>Flipped layout.</h4>
        <p>You don’t need to prove anyone’s fault to be eligible.</p>
        <p>Typically, employees are covered by the employer's insurer.  In Victoria this is the WorkCover compensation scheme.</p>
        <p>If you are an employee of a Commonwealth or prescribed large business, compensation is available to you under the national Comcare scheme.</p>
        <a href="/another-page">Visit our Comcare page for information specific to this scheme.</a>
        `,
    imgRight: true
};
