import React from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import FeatureImageAndText from '~2-components/FeatureImageAndText/FeatureImageAndText';
import { featureImageAndTextWithLink, featureImageAndTextWithHeading, featureImageAndTextRight } from '~data/featureImageAndTextData';

const contentWithImageEg = `import FeatureImageAndText from '~2-components/FeatureImageAndText/FeatureImageAndText';

<FeatureImageAndText
    imgUrl = '' //Required
    imgAlt = '' //Required
    richTextContent = '' //Required, only allows paragraphs and links.
    imgRight = boolean // if 'true', then image will appear on right side
/>
`;

const SgContentWithImage = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const KontentFeatureImageAndText = useStaticQuery(
        graphql`
            query SGFeatureImageAndText {
                allKontentItemFeatureImageAndText {
                    nodes {
                        elements {
                            flip_layout {
                                value {
                                    codename
                                }
                            }
                            image {
                                value {
                                    description
                                    width
                                    height
                                    url
                                }
                            }
                            text {
                                value
                            }
                        }
                        system {
                            id
                        }
                    }
                }
            }
        `);

    const featureImageAndText = KontentFeatureImageAndText.allKontentItemFeatureImageAndText.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>Content with Image</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={contentWithImageEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>

            <FeatureImageAndText {...featureImageAndTextWithLink} />

            <FeatureImageAndText {...featureImageAndTextWithHeading} />

            <FeatureImageAndText {...featureImageAndTextRight} />

            <section className="sg-content">
                <div className="container">
                    <h3>Feature Image and Text in Kontent</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {featureImageAndText.map((item, index) => {
                        return (
                            <FeatureImageAndText key={index} {...item} />
                        );
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgContentWithImage;
