import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';

const FeatureImageAndText = props => {
    let imgUrl, imgAlt, richTextContent, imgRight;
    let description, id, image; //Used for Gatsby Image

    if (props.system) {
        // data comes from CMS
        const itemID = props.system.id;

        const featureImageAndTextItem = useStaticQuery(
            graphql`
            query FeatureImageAndText {
                allKontentItemFeatureImageAndText {
                    nodes {
                        elements {
                            flip_layout {
                                value {
                                    codename
                                }
                            }
                            image {
                                value {
                                    description
                                    name
                                    fluid(maxWidth: 584) {
                                        ...KontentAssetFluid_withWebp
                                    }
                                }
                            }
                            text {
                                value
                            }
                        }
                        system {
                            id
                        }
                    }
                }
            }
        `);

        const match = useMemo(() => (
            featureImageAndTextItem.allKontentItemFeatureImageAndText.nodes.find((n) => itemID === n.system.id)
        ), [featureImageAndTextItem, itemID]);

        //interface
        const imageAttr = match.elements.image.value[0];

        //gatsby image
        if(imageAttr) {
            description = imageAttr.description;
            id = imageAttr.name;
            image = imageAttr.fluid;
        }

        richTextContent = match.elements.text.value;
        imgRight = match.elements.flip_layout.value.length > 0 ? match.elements.flip_layout.value[0] : false;
    } else {
        imgUrl = props.imgUrl;
        imgAlt = props.imgAlt;
        richTextContent = props.richTextContent;
        imgRight = props.imgRight ? props.imgRight : false;
    }

    return (
        <div className="feature-image-and-text">
            <div className={`container ${imgRight ? 'img-right' : ''}`}>
                <div className="image-container">
                    {(props.system && image) &&
                        <GatsbyImage
                            key={id}
                            alt={description}
                            fluid={image}
                        />
                    }
                    {!props.system &&
                        <img src={imgUrl} alt={imgAlt} />
                    }
                </div>
                <div className="text-container" dangerouslySetInnerHTML={{ __html: richTextContent }} />
            </div>
        </div>
    );
};

export default FeatureImageAndText;

FeatureImageAndText.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.required
    }),
    imgUrl: PropTypes.string,
    imgAlt: PropTypes.string,
    richTextContent: PropTypes.string,
    imgRight: PropTypes.bool
};
